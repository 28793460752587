import {isExternal, isWithoutRegistration} from '@wix/wix-events-commons-statics'
import {MouseEventHandler} from 'react'
import {getEventDetailsPageUrl, getEventUrl} from '../../selectors/events'
import {AppProps} from '../app/interfaces'
import {connect} from '../runtime-context/connect'
import {LinkToPageOwnProps, LinkToPageStateProps} from './interface'
import {LinkToPage as LinkToPagePresentation} from './link-to-page'

const mapRuntime = (
  {state, actions, baseUrl, pageUrl, queryParams}: AppProps,
  {event, noop, allowExternal}: LinkToPageOwnProps,
): LinkToPageStateProps => {
  const url = allowExternal
    ? getEventUrl(state, event, baseUrl, pageUrl?.relativeUrl, queryParams)
    : getEventDetailsPageUrl(state, event, baseUrl, pageUrl?.relativeUrl, queryParams)

  let onClick: MouseEventHandler
  if (noop) {
    onClick = e => e.preventDefault()
  } else if (url) {
    onClick = e => {
      e.stopPropagation()
      actions.onLinkNavigatedToPage(event)
    }
  } else {
    onClick = e => {
      e.stopPropagation()
      actions.navigateToPage(event)
    }
  }

  return {
    url,
    isExternalUrl: allowExternal && isExternal(event),
    onClick,
    disabled: isWithoutRegistration(event) || (noop && !url),
  }
}

export const LinkToPage = connect<LinkToPageOwnProps, LinkToPageStateProps>(mapRuntime)(LinkToPagePresentation)
